import Swal from 'sweetalert2';
import moment from "moment";
import { formatIsoDate } from '@/helpers/dates.js';


function validateAgeRelationship(kinship, birthDateBeneficiary, birthDateClient) {
  const clientBirthDate = moment(formatIsoDate(birthDateClient,"/"));
  const beneficiariesDate = moment(formatIsoDate(birthDateBeneficiary, "/"));

  let relationshipError = false;
  let after = false;
  
  const age = moment().diff(beneficiariesDate, 'years');

  switch (kinship) {
    case "ESPOSO":
      relationshipError = age < 16
      break;
    case "ESPOSA":
      relationshipError = age < 16
      break;
    case "HIJO":
      relationshipError = clientBirthDate.isAfter(beneficiariesDate);
      after = true;
      break;
    case "HIJA":
      relationshipError = clientBirthDate.isAfter(beneficiariesDate);
      after = true;
      break;
    case "MADRE":
      relationshipError = clientBirthDate.isBefore(beneficiariesDate);
      after = false;
      break;
    case "PADRE":
      relationshipError = clientBirthDate.isBefore(beneficiariesDate);
      after = false;
      break;
    case "ABUELO":
      relationshipError = clientBirthDate.isBefore(beneficiariesDate);
      after = false;
      break;
    case "ABUELA":
      relationshipError = clientBirthDate.isBefore(beneficiariesDate);
      after = false;
      break;
  }

  if(kinship == 'ESPOSO' || kinship == 'ESPOSA') {
    if(relationshipError){
      showMessage('warning', 'Aviso', `La fecha de nacimiento de ${kinship} no puede ser menor a 16, favor de ingresar una fecha válida`, 'OK', '#FEB72B');
        return relationshipError;
      }
  }
  if (relationshipError) {
    showMessage(
      "warning",
      "Aviso",
      `La fecha de nacimiento de ${kinship} no puede ser ${
        after ? "menor" : "mayor"
      } a la fecha de nacimiento del solicitante, favor de ingresar una fecha válida`,
      "OK",
      "#FEB72B"
    );
  }

  return relationshipError;
}
function showMessage(
  type,
  title,
  message,
  confirmButtonText = "OK",
  confirmButtonColor = "#FEB72B",
  redirectTo = ""
) {
  Swal.fire({
    title: title,
    html: message,
    icon: type,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: confirmButtonColor,
  }).then(() => {
    if (redirectTo != "") {
      router.push(redirectTo);
    }
  });
}

export {
    validateAgeRelationship,
    showMessage
}