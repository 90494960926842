<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                
                <IssuanceBeneficiariesComponent ref="ComponentsBenefiariesRef" :view_class_view="view_class_view" :validations="validations" :payment_methods="payment_methods" :payment_frequencies="payment_frequencies" :relationship_list="relationshipList" />

                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/beneficiaries.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { helpers, requiredIf, minLength, maxLength } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { availableFormStorage, getLocalStorage, createLocalStorage } from '@/helpers/storage.js';
import {formatIsoDate, formatEuropeanDate} from '@/helpers/dates.js';

import moment from 'moment';
import Swal from 'sweetalert2';
import Quote from '@/classes/Quotation.js';
  import { validateAgeRelationship } from '@/helpers/validateAgeWithRelationship.js';


export default {
    name: "Beneficiaries",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const questionTitle = ref("Beneficiarios")
        const ComponentsBenefiariesRef = ref(null);
        const clientInformation = ref(null);
        const category = ref("");
        const quoteClass = ref(null);
        const percentageCompleted = ref(0);

        let view_class_view = {
            title: "",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none ",
                submit: "bg-black text-white"
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:grid-cols-1 sm:gap-2",
        };
        const validations = {
            names:{
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minLength: helpers.withMessage(
                    "El campo nombre debe contener mínimo 2 caracteres.",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "El campo nombre debe contener máximo 30 caracteres.",
                    maxLength(30)
                ),
                pattern: helpers.withMessage(
                    "El campo nombre no acepta caracteres especiales.",
                    function (value, index) {
                        if (index.name != "") {
                        return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                        } else {
                        return true;
                        }
                    }
                ),
                $autoDirty: true 
            },
            paternal_name:{
                required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                    if(e.maternal_name == "") return true;
                    return false;
                })),
                minLength: helpers.withMessage(
                    "El campo apellido paterno debe contener mínimo 2 caracteres.",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "El campo apellido paterno debe contener máximo 30 caracteres.",
                    maxLength(30)
                ),
                pattern: helpers.withMessage(
                    "El campo apellido paterno no acepta caracteres especiales.",
                    function (value, index) {
                        if (index.paternal_name != "") {
                        return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                        } else {
                        return true;
                        }
                    }
                ),
                $autoDirty: true 
            },
            maternal_name:{
                required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                    if(e.paternal_name == "") return true;
                    return false;
                })),
                minLength: helpers.withMessage(
                    "El campo apellido materno debe contener mínimo 2 caracteres.",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "El campo apellido materno debe contener máximo 30 caracteres.",
                    maxLength(30)
                ),
                pattern: helpers.withMessage(
                    "El campo apellido materno no acepta caracteres especiales.",
                    function (value, index) {
                        if (index.maternal_name != "") {
                        return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                        } else {
                        return true;
                        }
                    }
                ),
                $autoDirty: true 
            },
            birthdate:{
                required: helpers.withMessage('El campo es requeridos', requiredIf(true)),
                isValidWithRelationship: helpers.withMessage('La fecha seleccionada es invalida', function(value, event){
                    if(event.relationship == "" || value == "") return true;
                    const birthdayUser = JSON.parse(sessionStorage.getItem("user-information")).user_data.birthday;
                    const isInvalid = validateAgeRelationship(event.relationship, value, birthdayUser);
                    return isInvalid? false: true;
                }),
                isBefore: helpers.withMessage('La fecha de nacimiento no puede ser igual o mayor a la fecha actual', function(value, event){
                    if(event.relationship == 'HIJO') return true;

                    var currentDate = moment().startOf('day');
                    var date = moment(formatIsoDate(value,'/'), 'YYYY-MM-DD').startOf('day');
                    var isBefore = date.isBefore(currentDate);
                    return isBefore;
                }),
                $autoDirty: true 
            },
            percentage: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minPercent: helpers.withMessage('El porcentage debe ser mayor a 0', function(value){
                    console.log("porcentaje: ", 0);
                    return !(value === 0)
                }),
                $autoDirty: true 
            },
            relationship: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                $autoDirty: true 
            }
        };
        const payment_methods = [{value: 'tarjeta de crédito', text: 'Tarjeta de crédito'}, {value: 'tarjeta de débito', text: 'tarjeta de débito'}, {value: 'transferencia bancaria', text: 'transferencia bancaria'}, {value: 'descuento por nomina', text: 'Descuento por nomina'}];
        const payment_frequencies = [{value: 'mensual', text: 'Mensual'}];
        const relationshipList = [
            {value: "ESPOSO", text:"ESPOSO"},
            {value: "ESPOSA", text:"ESPOSA"},
            {value: "PADRE", text:"PADRE"},
            {value: "MADRE", text:"MADRE"},
            {value: "HIJO", text:"HIJO"},
            {value: "HIJA", text:"HIJA"},
            {value: "HERMANO", text:"HERMANO"},
            {value: "HERMANA", text:"HERMANA"},
            {value: "ABUELO", text:"ABUELO"},
            {value: "ABUELA", text:"ABUELA"},
        ]
        
        let isActive = ref(true);

        const type_client = {
            "Para Adicional": configuration_for_aditional,
            "Para socio":  get_user_information
        }

        onMounted(async () => {
            quoteClass.value = new Quote();
            category.value = getCategory();

            const quote = quoteClass.value.get(category.value);
            type_client[quote.is_additional_insured] == undefined ? "" : type_client[quote.is_additional_insured]();

            isLoading.value = false;
            loadClientInformation();
            loadFormStorage();
        });

        function configuration_for_aditional()
        {
            set_title();
        }
        
        function set_title()
        {
            questionTitle.value += " del adicional";
        }

        function get_user_information()
        {
            const user_information = JSON.parse(sessionStorage.getItem("user-information"));
            if(user_information.beneficiaries == null) return;
            for(var item of user_information.beneficiaries)
            {
                item.birthday = item.birthday.split("-").reverse().join("/");
                item.birthdate = item.birthday;
                ComponentsBenefiariesRef.value.beneficiariesList.push({
                    ...item,
                    names: `${item.name} ${item.middle_name != null? item.middle_name : ''}`.trim()
                });  
            }
        }

        const loadClientInformation = () => {
            category.value = getCategory();
            clientInformation.value = getLocalStorage(`quote-${category.value}`);
        }

        const getCategory = () => {
            return route.params.product;
        }
        
        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('issuance-beneficiaries')) return;
                
                const data = getLocalStorage('issuance-beneficiaries');
            
                ComponentsBenefiariesRef.value.beneficiariesList = data;
                isActive.value = true;
            }catch(error){
                console.log("error:", error)
            }
        }

        const saveData = (values, category) => {
            if(values != null){
                const nameStorage = `issuance-${category}`;
                let issuance = getLocalStorage(nameStorage);
                issuance.beneficiaries = values; 
                createLocalStorage(nameStorage, issuance);
            }
            
            const totalPercent = getTotalPercent(values);
            if(totalPercent < 100){
                showMessage('info', 'Aviso', `El porcentaje total entre todos los beneficiarios debe ser 100%`, 'OK', '#FEB72B');
                percentageCompleted.value = false;
            }

            percentageCompleted.value = true;
        }

        const getTotalPercent = (beneficiaries) => {
            if(beneficiaries == null || beneficiaries == undefined) return 0;

            let totalPercent = 0;
            for (let index = 0; index < beneficiaries.length; index++) {
                const element = beneficiaries[index];
                totalPercent = element.percentage + totalPercent;
            }

            return totalPercent;
        }

        const next = async ()   => {
            const ComponentsBenefiariesRefs = ComponentsBenefiariesRef.value.execute_validation();
            
            saveData(ComponentsBenefiariesRefs, category.value);
            
            if(!ComponentsBenefiariesRefs || !percentageCompleted.value) return false ;
            createLocalStorage('issuance-beneficiaries', ComponentsBenefiariesRefs);
            router.push('carga-documentos')
        }

        const redirect_history = () => router.go(-1);

        return { 
            validations,
            view_class_view,
            isActive,
            next,
            questionTitle,
            ComponentsBenefiariesRef,
            payment_methods,
            payment_frequencies,
            relationshipList,
            isLoading,
            redirect_history
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>